<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarDataEspecial = true"
    ></EditButton>
    <SideBar
      title="Editar data especial"
      @hide="cancelar"
      :active="modalEditarDataEspecial"
      size="large"
      :item="data"
      :disabled="!$atividades.permissoes.includes(15)"
      textSucces="Atualizar"
      @excluir="exluirDataEspecial()"
      @status="mudarStatusDataEspecial()"
      @success="submitForm()"
    >
      <div
        slot="body"
        class="w-full vs-con-loading__container"
        :id="'editarDatasEspeciais' + this.data.id"
      >
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <vs-input
              class="w-full"
              name="nome"
              v-validate="'required'"
              label="Nome"
              v-model="data.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <vs-input
              class="w-full"
              type="date"
              name="data"
              v-validate="'required'"
              label="Data"
              v-model="data.data"
            />
            <span class="text-danger text-sm" v-show="errors.has('data')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <div class="flex w-full">
              <vs-switch v-model="data.ocio" color="success"></vs-switch>
              <h6 class="ml-6 mt-1">Dia ocioso</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <div class="flex w-full">
              <vs-switch v-model="data.nivel" color="success"></vs-switch>
              <h6 class="ml-6 mt-1">Nivel</h6>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: { data: Object },
  data() {
    return {
      modalEditarDataEspecial: false,
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.data);
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.editarDataEspecial();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarDataEspecial() {
      await this.$vs.loading({
        container: `#editarDatasEspeciais${this.data.id}`,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put(
          `datas-especiais/${this.data.id}`,
          this.data
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da data especial N°" + res.id,
        };
        await this.$logger(logData);
        this.modalEditarDataEspecial = false;
        this.$emit("update");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `#editarDatasEspeciais${this.data.id} > .con-vs-loading`
        );
      }
    },
    async exluirDataEspecial() {
      await this.$vs.loading({
        container: `#editarDatasEspeciais${this.data.id}`,
        scale: 0.6,
      });
      try {
        await this.$http.delete(`data/${this.data.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da data especial N°" + this.data.id,
        };
        await this.$logger(logData);
        this.$emit("update");
        this.modalEditarDataEspecial = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `#editarDatasEspeciais${this.data.id} > .con-vs-loading`
        );
      }
    },
    async mudarStatusDataEspecial() {
      await this.$vs.loading({
        container: `#editarDatasEspeciais${this.data.id}`,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put(`data/${this.data.id}`, {
          lixeira: !this.data.lixeira,
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da data especial " + res.id,
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.$emit("update");
        this.modalEditarDataEspecial = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `#editarDatasEspeciais${this.data.id} > .con-vs-loading`
        );
      }
    },
    cancelar() {
      Object.assign(this.data, this._beforeEditingCache);
      this.modalEditarDataEspecial = false;
    },
  },
  components: { ...components },
};
</script>

<style></style>
