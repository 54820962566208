<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalCadastroDataEspecial = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar data especial"
      @hide="modalCadastroDataEspecial = false"
      size="large"
      :active="modalCadastroDataEspecial"
      @success="submitForm()"
    >
      <div
        slot="body"
        class="w-full vs-con-loading__container"
        id="cadatrarDataEspecial"
      >
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <vs-input
              class="w-full"
              name="nome"
              v-validate="'required'"
              label="Nome"
              v-model="data.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <vs-input
              class="w-full"
              type="date"
              name="data"
              v-validate="'required'"
              label="Data"
              v-model="data.data"
            />
            <span class="text-danger text-sm" v-show="errors.has('data')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <div class="flex w-full">
              <vs-switch v-model="data.ocio" color="success"></vs-switch>
              <h6 class="ml-6 mt-1">Dia ocioso</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col
            vs-w="12"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <div class="flex w-full">
              <vs-switch v-model="data.nivel" color="success"></vs-switch>
              <h6 class="ml-6 mt-1">Nivel</h6>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      data: {},
      modalCadastroDataEspecial: false,
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.cadastrarDataEspecial();
        } else {
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarDataEspecial() {
      await this.$vs.loading({
        container: "#cadatrarDataEspecial",
        scale: 0.6,
      });
      try {
        const res = await this.$http.post("datas-especiais", this.data);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da data especial N°" + res.id,
        };
        await this.$logger(logData);
        this.data = {};
        this.$vs.notify(this.$notify.Success);
        await this.$emit("update");
        this.modalCadastroDataEspecial = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#cadatrarDataEspecial > .con-vs-loading");
      }
    },
  },
  components: { ...components },
};
</script>

<style></style>
